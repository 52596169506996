/*
 *
 *  * Copyright 2015-2018 Afero, Inc.
 *
 */

import clearReducerAndStorageProcess from '../redux/thunks/clearReducerAndStorageProcess';
import keycloak from "../keycloak";
import {getUserDetails} from "./http_request_wrapper";

/**
 * Checks if the current credentials are still valid.
 *
 * @return <code>true</code>, if they are still valid. <code>false</code>, if they have expired.
 */

async function isAuthenticatedPromise() {
  try {

    // Now refresh the user details. This is a final check if the current token actually has a valid value.
    /*stored accountId to be use by other middlewares*/
    let userDetails = await getUserDetails();
    const ownerFound = userDetails.accountAccess.some(props => {
      if (props.privileges.owner === true) {
        localStorage.setItem('accountId', props.account.accountId);
        return true;
      }
      return false;
    });

    if (!ownerFound) {
      const error = new Error('User does not own any account.');
      error.code = 403;
      throw error;
    } else {
      return true;
    }
  } catch (error) {
    console.log(`Token expired: `, error);
    return false;
  }
}

async function handleAuthentication(router, dispatch) {
  await keycloak.updateToken(-1).then(
      (response) => {
        localStorage.setItem('kc_token', keycloak.token);
        localStorage.setItem('kc_refreshToken', keycloak.refreshToken);
      })
      .catch(error => {
        console.log("error: ", error)
      })
  if (!keycloak.hasResourceRole('inspector')) {
    signOutPromise(dispatch);
    router.navigate(`/error`);
    return false;
  }

  if (!await isAuthenticatedPromise()) {
    signOutPromise(dispatch);
    router.navigate(`/`);
    return false;
  }

  return true;
}

async function signInPromise(handler) {
  try {
      keycloak.userInfo = await keycloak.loadUserProfile();
  } catch (error) {
    throw error; //signinProcess will catch the error
  }
}

function handleKeycloakRefreshPage(onSuccess){
  const token = localStorage.getItem('kc_token');
  const refreshToken = localStorage.getItem('kc_refreshToken');
  if (!keycloak.authenticated){
    keycloak.init({onLoad: 'login-required', token, refreshToken, checkLoginIframe: false}).then(
        success => {
          localStorage.setItem('kc_token', keycloak.token);
          localStorage.setItem('kc_refreshToken', keycloak.refreshToken);
          onSuccess();
        });
  }else {
    onSuccess();
  }

}

/*signout, but not real a promise*/
function signOutPromise(dispatch) {
  clearReducerAndStorageProcess()(dispatch);
  keycloak.logout();
}

export { isAuthenticatedPromise, signInPromise, signOutPromise, handleKeycloakRefreshPage, handleAuthentication };
