import React, {Component} from 'react';
import {
    Button,
    ButtonGroup, Dropdown
} from 'semantic-ui-react';
import Config500Obj from "../../../util/encoder/configrules/Config500Obj";
import WizardComponent from "./WizardComponent";

export default class Config500Wizard extends Component{
    constructor(props) {
        super(props);
        this.state = {
            props: props,
            configValue: props.configAttribute,
            config: new Config500Obj(props.configAttribute),
            hasError: true
        }
        this.state.config.decode();
        this.state.config.runRules()
        this.state.page = 24;
        this.props.setInfoText('The Configuration Attribute. ' +
            'Configure the device. ');
        this.state.pagesOrder = [24]
        this.submitThroughDropdown = this.submitThroughDropdown.bind(this);
    }

    render() {
        return (
            <div>
                <WizardComponent attrid={this.props.attrid}
                                 config={this.state.config}
                                 page={24}
                                 submitButton={() =>
                                     <ButtonGroup color={'orange'}>
                                        <Button id='encodeButton' className={'submitButton tooltip'}
                                                onClick={(event)=>this.submit(event)}
                                                data-attrid={this.props.attrid}
                                                data-attrtype={this.props.attrtype}
                                                data-datatype={this.props.datatype}
                                                data-datalength={this.props.datalength}>
                                                <span className="tooltiptext" style={{fontSize: "small"}}>
                                                    Changes will be applied on reboot.
                                                </span>
                                            Submit
                                        </Button>
                                        <Dropdown
                                            className='button icon noTextMargin'
                                            style={{margin:'0px', padding:'10px'}}
                                            floating
                                            options={[
                                                { key: 'submit', text: 'Submit', value: false, onClick: this.submitThroughDropdown},
                                                { key: 'reboot', text: 'Submit & Reboot', value: true, onClick: this.submitThroughDropdown},
                                            ]}
                                            trigger={<></>}
                                        />
                                    </ButtonGroup>}>
                </WizardComponent>
            </div>
        );
    }

    submit(event) {
        this.state.config.encode();
        event.target.userInput = {value: this.state.config.configValue};
        event.target.rebootDevice = this.state.rebootOnSubmit;
        this.state.props.onSubmit(event);
    }

    submitThroughDropdown(event, {value}) {
        let submitBtn = event.target.parentElement.parentElement.parentElement.parentElement.querySelector('.submitButton');
        this.setState({rebootOnSubmit: value}, ()=> submitBtn.click())
    }

}

function isValid500Attribute(attribute) {
    return attribute.substring(0, 2) === '01' && attribute.length >= 98;
}

export {isValid500Attribute};
