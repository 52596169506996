import React, { Component } from 'react';

import AttributeComponent from './AttributeComponent';
import DeviceInforComponent from './DeviceInforComponent';
import FooterComponent from './FooterComponent';
import HeaderMainComponent from './HeaderMainComponent';
import LoaderComponent from '../miscellaneous/LoaderComponent';
import SidebarComponent from './SidebarComponent';
import VisualComponent from './VisualComponent';
import keycloak from "../../keycloak";

export default class MainComponent extends Component {
  constructor(props) {
    super(props);
    let { direct } = JSON.parse(localStorage.getItem('selectedDeviceObj'));
    this.state = {
      /*MainDeviceComponent > RssiDeviceComponent*/
      currentConnection: direct === 'false' ? 'bluetooth' : 'wifi', //when user refresh under inital render, currentConnection will be define through localStorage
      friendlyNameEdit: false,
      connectionLogsClassNameContainer: direct === 'false' ? 'connectionLogs_close_container' : 'connectionLogs_open_container',
      searchText: '',
      toggleConnectionLogs: direct === 'false' ? 'open' : 'close', //if direct is true, then it is connected to wifi
      updatedAttrId: '',

      /*MainDeviceComponent > AttributeDeviceComponent*/
      propEdited: {},
      attrIdSortArrow: 'up', //default sort by smallest to largest
      attrNameSortArrow: 'down',
      attrDateSortArrow: 'down',
      aferoIdIconColor: '#ffac3e',
      aferoIdIconType: 'sort up',
      aferoNameIconColor: 'white',
      aferoNameIconType: 'sort down',
      aferoDateIconColor: 'white',
      aferoDateIconType: 'sort down',
      devIdIconColor: '#ffac3e', //default sort by smallest to largest
      devIdIconType: 'sort up', //default sort by smallest to largest
      devNameIconColor: 'white',
      devNameIconType: 'sort down',
      devDateIconColor: 'white',
      devDateIconType: 'sort down',
      showAttributes: true,
      attrFilter: ()=>{return true},
    };
  }

  /*LifeCycle: Growth/Update*/
  /*in this life cycle we are tracking currentConnection and propsEdited*/
  UNSAFE_componentWillReceiveProps = nextProps => {
    let { updateDeviceAttributeFlag, updateAferoAttributeFlag } = nextProps;
    let { connectionLogs, default_updatedErrorMsg, getUserStateFlag, getAferoAttributesFlag, getDeviceAttributesFlag } = this.props;
    if (this.props.deviceAttribute.deviceId !== nextProps.deviceAttribute.deviceId) {
      this.setState({showAttributes:true})
    }
    //when Device or Afero's attribute update is successful, propsEdited need to close and error message have to be clear out
    if (updateDeviceAttributeFlag === true) {
      let { propEdited, updatedAttrId } = this.state;
      propEdited[updatedAttrId] = false;
      default_updatedErrorMsg('deviceAttribute', updatedAttrId);
      this.setState({ propEdited: propEdited });
    }

    if (updateAferoAttributeFlag === true) {
      let { propEdited, updatedAttrId } = this.state;

      propEdited[updatedAttrId] = false;
      default_updatedErrorMsg('aferoAttribute', updatedAttrId);
      this.setState({ propEdited: propEdited });
    }

    //keep track of currentConnection
    if (getUserStateFlag && getAferoAttributesFlag && getDeviceAttributesFlag) {
      let { deviceId } = JSON.parse(localStorage.getItem('selectedDeviceObj'));
      this.setState({ currentConnection: connectionLogs[deviceId].connections.activeConnection });
    }
  };

  /************************************---VisualComponent's Handlers---************************************/
  /*clear the deviceLog's filter*/
  handle_searchText = () => {
    this.setState({ searchText: '' });
  };

  /*clear deviceLog, default searchText */
  handle_clearDevLogs = () => {
    this.props.clear_deviceLogs();
    this.setState({ searchText: '' });
  };

  /*take user's input to filter the deviceLog*/
  onChange_searchText = event => {
    let searchTextTarget = event.target.value.toLowerCase().replace(/[&\\#,+()$~%'"*?<>{}[\]']/g, '');

    if (searchTextTarget) {
      this.setState({ searchText: searchTextTarget });
    } else {
      this.setState({ searchText: '' });
    }
  };

  /*download deviceLogs values as txt doc*/
  handle_downloadDevLogs = () => {
    let { searchText } = this.state;
    let { deviceLogs } = this.props;
    let { friendlyName, deviceId } = JSON.parse(localStorage.getItem('selectedDeviceObj'));
    let expression = new RegExp(searchText, 'g');

    if (deviceLogs[deviceId] && deviceLogs[deviceId].logs.length > 0) {
      let logs = [];

      deviceLogs[deviceId].logs.forEach(str => {
        if (searchText === '') {
          logs.push(str);
        } else {
          //download deviceLogs based on user's filter
          let matched = str.toLowerCase().match(expression);

          if (matched) {
            logs.push(str);
          }
        }
      });
      const pre = JSON.stringify(logs, null, 2)
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;');
      let a = document.createElement('a');
      let file = new Blob([pre], { type: 'text/plain' });

      a.href = URL.createObjectURL(file);
      a.download = friendlyName + ' deviceLogs.txt';
      a.click();
    } else {
      return false;
    }
  };

  /*download connectionLogs values as txt doc*/
  handle_downloadConnectionLogs = () => {
    let { connectionLogs } = this.props;
    let { friendlyName, deviceId } = JSON.parse(localStorage.getItem('selectedDeviceObj'));

    let connectionLogsFiltered = connectionLogs[deviceId];
    const pre = JSON.stringify(connectionLogsFiltered, null, 2)
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');

    let a = document.createElement('a');
    let file = new Blob([pre], { type: 'application/json' });
    a.href = URL.createObjectURL(file);
    a.download = (new Date()).toISOString() + '_' + friendlyName + '_connectionLogs.json';
    a.click();
  };

  /*user can hide/display RSSI Graph, this will force `rssiDevice.scss` height to alter*/
  handle_toggleConnectionLogs = () => {
    let { toggleConnectionLogs } = this.state;
    let { flag_displayCurrentConnection } = this.props;

    if (toggleConnectionLogs === 'open') {
      flag_displayCurrentConnection(true);

      this.setState({
        toggleConnectionLogs: 'close',
        connectionLogsClassNameContainer: 'connectionLogs_open_container',
      });
    } else if (toggleConnectionLogs === 'close') {
      flag_displayCurrentConnection(false);

      this.setState({
        toggleConnectionLogs: 'open',
        connectionLogsClassNameContainer: 'connectionLogs_close_container',
      });
    }
  };

  /************************************---SidebarComponent's Handlers---************************************/
  /*multiple things happen when a device is clicked under mainComponent*/
  handle_selectDeviceId = event => {
    event.preventDefault();
    let { flag_displayCurrentConnection, router, connectionLogs, flag_repullApi } = this.props;
    let { deviceid, direct, friendlyname } = event.currentTarget.dataset;

    this.setState({ propEdited: {} }); //propsEdited need to close by defaulting it to an empty {}

    //updated selectedDeviceObj need to be store to localStorage
    let obj = {
      deviceId: deviceid,
      friendlyName: friendlyname,
      direct: direct
    };
    localStorage.setItem('selectedDeviceObj', JSON.stringify(obj));

    //SERV-2549: Wi-Fi RSSI pane should be open by default
    if (connectionLogs[deviceid].connections.activeConnection === 'wifi') {
      flag_displayCurrentConnection(true);
      this.setState({
        currentConnection: 'wifi',
        connectionLogsClassNameContainer: 'connectionLogs_open_container',
      });
    } else {
      flag_displayCurrentConnection(false);
      this.setState({
        currentConnection: 'bluetooth',
        connectionLogsClassNameContainer: 'connectionLogs_close_container',
      });
    }

    //default all of the arrows to its original state
    this.setState({
      attrIdSortArrow: 'up',
      attrNameSortArrow: 'down',
      attrDateSortArrow: 'down',
      aferoIdIconColor: '#ffac3e',
      aferoIdIconType: 'sort up',
      aferoNameIconColor: 'white',
      aferoNameIconType: 'sort down',
      aferoDateIconColor: 'white',
      aferoDateIconType: 'sort down',
      devIdIconColor: '#ffac3e',
      devIdIconType: 'sort up',
      devNameIconColor: 'white',
      devNameIconType: 'sort down',
      devDateIconColor: 'white',
      devDateIconType: 'sort down'
    });

    flag_repullApi(true); //give signal to start repulling API again
    if (obj.deviceId !== this.props.deviceAttribute.deviceId) {
      this.setState({showAttributes: false})
    }
    router.navigate(`/main/${obj.deviceId}`);
    //history.push({ pathname: `/main`, search: `?deviceID=${obj.deviceId}` }); //set URL param
  };

  /*highlight device when selected*/
  handle_myColor = deviceId => {
    let selectedDeviceObj = JSON.parse(localStorage.getItem('selectedDeviceObj'));
    if (selectedDeviceObj && deviceId === selectedDeviceObj.deviceId) {
      return '#454b4e'; //gray
    }
  };

  /************************************---HeaderMainComponent's Handlers---************************************/
  /*reroute to Account Component*/
  handle_routeAccount = event => {
    event.preventDefault();
    let { flag_displayCurrentConnection, router } = this.props;

    flag_displayCurrentConnection(false);
    router.navigate(`/account`);
  };

  /*reroute to Home Component*/
  handle_routeHome = event => {
    event.preventDefault();
    let { flag_displayCurrentConnection, router } = this.props;

    flag_displayCurrentConnection(false);
    router.navigate(`/home`);
  };

  /*reroute to Signout Component*/
  handle_routeSignOut = event => {
    event.preventDefault();
    let { clear_rootReducer, router } = this.props;

    clear_rootReducer();
    router.navigate(`/`);
    keycloak.logout()
  };

  /************************************---AttributeDeviceComponent's Handlers---************************************/
  /*allow user to download values as txt doc*/
  handle_downloadDeviceAttributes = () => {
    let { deviceAttribute } = this.props;
    let { friendlyName } = JSON.parse(localStorage.getItem('selectedDeviceObj'));

    const pre = JSON.stringify(deviceAttribute, null, 2)
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');

    let a = document.createElement('a');
    let file = new Blob([pre], { type: 'application/json' });

    a.href = URL.createObjectURL(file);
    a.download = (new Date()).toISOString() + '_' + friendlyName + '_deviceAttributes.json';
    a.click();
  };

  /*allow user to download values as txt doc*/
  handle_downloadAferoAttributes = () => {
    let { aferoAttribute } = this.props;
    let { friendlyName } = JSON.parse(localStorage.getItem('selectedDeviceObj'));

    const pre = JSON.stringify(aferoAttribute, null, 2)
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');

    let a = document.createElement('a');
    let file = new Blob([pre], { type: 'application/json' });

    a.href = URL.createObjectURL(file);
    a.download = (new Date()).toISOString() + '_' + friendlyName + '_aferoAttributes.json';
    a.click();
  };

  /*take user's input & update Device/Afero attribute*/
  handle_updateAttributes = event => {
    event.preventDefault();
    const { userInput, rebootDevice } = event.target;
    let { router, update_attributes } = this.props;
    let { attrid, attrtype, datatype, datalength } = event.currentTarget.dataset;

    const inputObj = {
      dataType: datatype,
      attrtype: attrtype,
      length: datalength,
      userInputValue: userInput.value,
      rebootDevice: rebootDevice
    };
    //payload is required for createDeviceActionPromise
    const payload = {
      type: 'attribute_write',
      attrId: Number(attrid),
      value: userInput.value
    };

    update_attributes(payload, inputObj, router);
    this.setState({ updatedAttrId: attrid });
  };

  /*close user's input form*/
  handle_closeInputForm = event => {
    event.preventDefault();
    let { propEdited } = this.state;
    let { default_updatedErrorMsg } = this.props;
    let { attrid, attrtype } = event.currentTarget.dataset;

    propEdited[attrid] = false;
    default_updatedErrorMsg(attrtype, attrid);
    this.setState({ propEdited: propEdited });
  };

  /*hide/show user's input*/
  handle_userInput = event => {
    event.preventDefault();
    let { propEdited } = this.state;
    let { attrid } = event.currentTarget.dataset;

    propEdited[attrid] = true;
    this.setState({ propEdited: propEdited });
  };

  /*sort all of Afero Attribute's columns by id, name, and update*/
  handle_sortAferoAttribute = event => {
    event.preventDefault();
    let { attrIdSortArrow, attrNameSortArrow, attrDateSortArrow } = this.state;
    let { sort_attributes } = this.props;
    let { column, attrtype } = event.currentTarget.dataset;

    switch (column) {
      case 'attrId':
        if (attrIdSortArrow === 'up') {
          sort_attributes(column, 'down', attrtype);

          this.setState({
            attrIdSortArrow: 'down',
            aferoIdIconType: 'sort down',
            aferoIdIconColor: '#ffac3e',
            aferoNameIconColor: 'white', //default other columns arrow to white
            aferoDateIconColor: 'white' //default other columns arrow to white
          });
        } else if (attrIdSortArrow === 'down') {
          sort_attributes(column, 'up', attrtype);

          this.setState({
            attrIdSortArrow: 'up',
            aferoIdIconType: 'sort up',
            aferoIdIconColor: '#ffac3e',
            aferoNameIconColor: 'white', //default other columns arrow to white
            aferoDateIconColor: 'white' //default other columns arrow to white
          });
        }
        break;

      case 'attrName':
        if (attrNameSortArrow === 'up') {
          sort_attributes(column, 'down', attrtype);

          this.setState({
            attrNameSortArrow: 'down',
            aferoNameIconType: 'sort down',
            aferoNameIconColor: '#ffac3e',
            aferoIdIconColor: 'white', //default other columns arrow to white
            aferoDateIconColor: 'white' //default other columns arrow to white
          });
        } else if (attrNameSortArrow === 'down') {
          sort_attributes(column, 'up', attrtype);

          this.setState({
            attrNameSortArrow: 'up',
            aferoNameIconType: 'sort up',
            aferoNameIconColor: '#ffac3e',
            aferoIdIconColor: 'white', //default other columns arrow to white
            aferoDateIconColor: 'white' //default other columns arrow to white
          });
        }
        break;

      case 'attrDate':
        if (attrDateSortArrow === 'up') {
          sort_attributes(column, 'down', attrtype);

          this.setState({
            attrDateSortArrow: 'down',
            aferoDateIconType: 'sort down',
            aferoDateIconColor: '#ffac3e',
            aferoNameIconColor: 'white', //default other columns arrow to white
            aferoIdIconColor: 'white' //default other columns arrow to white
          });
        } else if (attrDateSortArrow === 'down') {
          sort_attributes(column, 'up', attrtype);

          this.setState({
            attrDateSortArrow: 'up',
            aferoDateIconType: 'sort up',
            aferoDateIconColor: '#ffac3e',
            aferoNameIconColor: 'white', //default other columns arrow to white
            aferoIdIconColor: 'white' //default other columns arrow to white
          });
        }
        break;

      default:
        return false;
    }
  };

  /*sort all of Device Attribute's columns by id, name, and update*/
  handle_sortDeviceAttribute = event => {
    event.preventDefault();
    let { attrIdSortArrow, attrNameSortArrow, attrDateSortArrow } = this.state;
    let { sort_attributes } = this.props;
    let { column, attrtype } = event.currentTarget.dataset;

    switch (column) {
      case 'attrId':
        if (attrIdSortArrow === 'up') {
          sort_attributes(column, 'down', attrtype);

          this.setState({
            attrIdSortArrow: 'down',
            devIdIconType: 'sort down',
            devIdIconColor: '#ffac3e',
            devNameIconColor: 'white', //default other columns arrow to white
            devDateIconColor: 'white' //default other columns arrow to white
          });
        } else if (attrIdSortArrow === 'down') {
          sort_attributes(column, 'up', attrtype);

          this.setState({
            attrIdSortArrow: 'up',
            devIdIconType: 'sort up',
            devIdIconColor: '#ffac3e',
            devNameIconColor: 'white', //default other columns arrow to white
            devDateIconColor: 'white' //default other columns arrow to white
          });
        }
        break;

      case 'attrName':
        if (attrNameSortArrow === 'up') {
          sort_attributes(column, 'down', attrtype);

          this.setState({
            attrNameSortArrow: 'down',
            devNameIconType: 'sort down',
            devNameIconColor: '#ffac3e',
            devIdIconColor: 'white', //default other columns arrow to white
            devDateIconColor: 'white' //default other columns arrow to white
          });
        } else if (attrNameSortArrow === 'down') {
          sort_attributes(column, 'up', attrtype);

          this.setState({
            attrNameSortArrow: 'up',
            devNameIconType: 'sort up',
            devNameIconColor: '#ffac3e',
            devIdIconColor: 'white', //default other columns arrow to white
            devDateIconColor: 'white' //default other columns arrow to white
          });
        }
        break;

      case 'attrDate':
        if (attrDateSortArrow === 'up') {
          sort_attributes(column, 'down', attrtype);

          this.setState({
            attrDateSortArrow: 'down',
            devDateIconType: 'sort down',
            devDateIconColor: '#ffac3e',
            devNameIconColor: 'white', //default other columns arrow to white
            devIdIconColor: 'white' //default other columns arrow to white
          });
        } else if (attrDateSortArrow === 'down') {
          sort_attributes(column, 'up', attrtype);

          this.setState({
            attrDateSortArrow: 'up',
            devDateIconType: 'sort up',
            devDateIconColor: '#ffac3e',
            devNameIconColor: 'white', //default other columns arrow to white
            devIdIconColor: 'white' //default other columns arrow to white
          });
        }
        break;
      default:
        return false;
    }
  };

  /************************************---DeviceInforComponent's Handlers---************************************/
  /*download deviceProfile*/
  handle_downloadDeviceProfile = () => {
    let { deviceProfile, deviceAttribute } = this.props;

    const pre = JSON.stringify(deviceProfile, null, 2)
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');

    let a = document.createElement('a');
    let file = new Blob([pre], { type: 'application/json' });
    a.href = URL.createObjectURL(file);
    a.download = (new Date()).toISOString() + '_' + deviceAttribute.friendlyName + '_deviceProfile.json';
    a.click();
  };

  handle_devAttrFilter = event => {
    this.setState({attrFilter: this.state.attrFilter});
  }
  handle_updateDeviceAttribute = (attributeType, accountId, deviceId) => {
    let { update_device_attributes } = this.props;
    update_device_attributes(attributeType, accountId, deviceId);
  };

  setDevAttributesFilter = (filter) => {
    this.setState({ attrFilter: filter });
  }

  render() {
    let {
      aferoIdIconColor,
      aferoIdIconType,
      aferoNameIconColor,
      aferoNameIconType,
      aferoDateIconColor,
      aferoDateIconType,
      currentConnection,
      devIdIconColor,
      devIdIconType,
      devNameIconColor,
      devNameIconType,
      devDateIconColor,
      devDateIconType,
      propEdited,
      connectionLogsClassNameContainer
    } = this.state;
    let {
      aferoAttribute,
      connectionLogs,
      deviceAttribute,
      getAferoAttributesFlag,
      getDeviceAttributesFlag,
      getUserStateFlag,
      displayCurrentConnectionFlag,
      userState
    } = this.props;
    return (
      <div>
        {!getUserStateFlag || !getAferoAttributesFlag || !getDeviceAttributesFlag ? (
          <LoaderComponent />
        ) : (
          <div className='main-container'>
            <div id='headerMainComponent-item'>
              <HeaderMainComponent
                handle_routeAccount={this.handle_routeAccount}
                handle_routeHome={this.handle_routeHome}
                handle_routeSignOut={this.handle_routeSignOut}
                userState={userState}
              />
            </div>
            <div id='sideBarComponent-item'>
              <SidebarComponent
                handle_myColor={this.handle_myColor}
                handle_selectDeviceId={this.handle_selectDeviceId}
                userState={userState}
              />
            </div>
            <div id='attributeComponent-item'>
              {!this.state.showAttributes ? <div style={{position:'relative'}}><LoaderComponent/></div>:
              <AttributeComponent
                aferoAttribute={aferoAttribute}
                aferoDateIconColor={aferoDateIconColor}
                aferoDateIconType={aferoDateIconType}
                aferoNameIconColor={aferoNameIconColor}
                aferoNameIconType={aferoNameIconType}
                aferoIdIconColor={aferoIdIconColor}
                aferoIdIconType={aferoIdIconType}
                deviceAttribute={deviceAttribute}
                devIdIconColor={devIdIconColor}
                devIdIconType={devIdIconType}
                devDateIconColor={devDateIconColor}
                devDateIconType={devDateIconType}
                devNameIconColor={devNameIconColor}
                devNameIconType={devNameIconType}
                propEdited={propEdited}
                handle_downloadAferoAttributes={this.handle_downloadAferoAttributes}
                handle_downloadDeviceAttributes={this.handle_downloadDeviceAttributes}
                handle_closeInputForm={this.handle_closeInputForm}
                handle_sortDeviceAttribute={this.handle_sortDeviceAttribute}
                handle_sortAferoAttribute={this.handle_sortAferoAttribute}
                handle_updateAttributes={this.handle_updateAttributes}
                handle_userInput={this.handle_userInput}
                userState={userState}
                handle_devAttrFilter={this.handle_devAttrFilter}
                attrFilter={this.state.attrFilter}
                setDevAttributesFilter={this.setDevAttributesFilter}
              />}
            </div>
            <div id='rssiComponent-item'>
              <VisualComponent
                connectionLogs={connectionLogs}
                currentConnection={currentConnection}
                displayCurrentConnectionFlag={displayCurrentConnectionFlag}
                connectionLogsClassNameContainer={connectionLogsClassNameContainer}
                handle_downloadConnectionLogs={this.handle_downloadConnectionLogs}
                handle_toggleConnectionLogs={this.handle_toggleConnectionLogs}
                deviceLogs={this.props.deviceLogs}
                handle_clearDevLogs={this.handle_clearDevLogs}
                handle_downloadDevLogs={this.handle_downloadDevLogs}
                searchText={this.state.searchText}
                onChange_searchText={this.onChange_searchText}
              />
            </div>
            <div id='deviceInfoComponent-item'>
              <DeviceInforComponent
                deviceAttribute={deviceAttribute}
                handle_downloadDeviceProfile={this.handle_downloadDeviceProfile}
                handle_updateDeviceAttribute = {this.handle_updateDeviceAttribute}
              />
            </div>
            <div id='footerComponent-item'>
              <FooterComponent />
            </div>
          </div>
        )}
      </div>
    );
  }
}
