const PERCENT_UNIT = '%';
const CELSIUS_UNIT = '° C';
const SECONDS_UNIT = ' sec';
const MSECONDS_UNIT = ' msec';
const HZ_UNIT = ' Hz';

function decodeDecimalData(value) {
    let retVal = 0;
    if (Array.isArray(value)) {
        // eslint-disable-next-line default-case
        switch (value.length) {
            case 1:
                retVal = `${parseInt(value, 16)}`;
                break;
            case 2:
                retVal = `${parseInt(value[1] + value[0], 16)}`;
                break;
        }
    } else {
        retVal = `${parseInt(value, 16)}`;
    }
    return retVal;
}

function decodeGPIO(value) {
    let decodedValue = decodeDecimalData(value)
    if (allFF(value)) {
        decodedValue = "n/a";
    } else {
        decodedValue = `${decodedValue}`;
    }
    return decodedValue;
}

function allFF(inBytes) {
    inBytes = String(inBytes);
    inBytes = inBytes.split('');
    return inBytes.every(element => {
        return element.toLowerCase() === 'f' || element.toLowerCase() === ',';
    })
}

function decodePercent(value) {
    return annotatedUnit(value, PERCENT_UNIT);
}

function decodeCelsius(value) {
    return annotatedUnit(value, CELSIUS_UNIT);
}

function decodeSeconds(value) {
    return annotatedUnit(value, SECONDS_UNIT);
}

function decodeMSeconds(value) {
    return annotatedUnit(value, MSECONDS_UNIT);
}

function decodeFrequency(value) {
    return annotatedUnit(value, HZ_UNIT);
}

function annotatedUnit(value, unit) {
    let decodedValue = decodeDecimalData(value);
    if (allFF(value)) {
        decodedValue = "n/a";
    } else {
        decodedValue = `${decodedValue}${unit}`;
    }
    return decodedValue;
}

function decodeLimitedDecimal(value, options) {
    let decodedValue = decodeDecimalData(value);
    if (allFF(value)) {
        decodedValue = "n/a";
    } else if (options.find(option => Number(option.value) === Number(decodedValue))) {
        let selected = options.find(option => Number(option.value) === Number(decodedValue));
        decodedValue = selected.key;
    } else {
        decodedValue = `UNEXPECTED VALUE: ${decodedValue}`
    }
    return decodedValue;
}

function decodeFlags(value, options){
    let decodedValue = `0b${hex2Bin(value)}`;
    let flags = [];
    let bit = 0;
    options.forEach((element, index) => {
        bit = decodedValue[decodedValue.length-(index+1)];
        flags.push({name: element["name"], value: (bit === "1") ? element["trueLabel"] : element["falseLabel"], bit: bit});
    })
    return flags;
}

function hex2Bin(inputBytes) {
    // Default assumption: input is a single byte as string
    let dec = parseInt(inputBytes, 16);
    let len = 8;
    // If it's an array, we've got to deal with endian-ness
    if (Array.isArray(inputBytes)) {
        len = inputBytes.length * 8;
        if (len === 8) {
            dec = parseInt(inputBytes, 16);
        } else if (len === 16) {
            dec = parseInt(inputBytes[1] + inputBytes[0], 16);
        }
    }
    // Convert to bits and pad the result with leading zeroes
    return ("0".repeat(len) + dec.toString(2)).slice(-len);
}

function decodeColor(value) {
    let version = decodeDecimalData(value.substring(0,2));
    let colorSets = value.substring(2);
    return {
        version: version,
        colorSets: decodeColorSets(colorSets)
    }
}

function decodeColorSets(value) {
    let decodedColorSets = [];
    if(value.length < 12){
        return decodedColorSets;
    }
    let colorSets = value.split(/(.{12})/).filter(Boolean);
    colorSets.forEach(set => decodedColorSets.push(decodeColorSet(set)));
    return decodedColorSets;
}

function decodeColorSet(value) {
    return {
        hex: value.substring(0,6),
        definition:{
            red: hexToTriplet(value.substring(0,2)),
            green: hexToTriplet(value.substring(2,4)),
            blue: hexToTriplet(value.substring(4,6))
        },
        tuning: {
            red: hexToTriplet(value.substring(6,8)),
            green: hexToTriplet(value.substring(8,10)),
            blue: hexToTriplet(value.substring(10))
        }
    }
}

function decodeRGB(value) {
    let decoded = {applicable: true};
    let decimal = decodeDecimalData(value)
    if (value === 'FF' || isNaN(decimal) || decimal === '255') {
        decoded.applicable = false;
    } else {
        let binary = parseInt(decimal).toString(2);
        let binaryArray = !!binary.match(/(..?)/g) ? binary.match(/(..?)/g) : []
        decoded.red = parseInt(binaryArray[0], 2);
        decoded.green = parseInt(binaryArray[1], 2);
        decoded.blue = parseInt(binaryArray[2], 2);
    }
    return decoded;
}

function hexToTriplet(hex) {
    return parseInt(hex, 16);
}

export {decodeDecimalData, decodeGPIO, decodePercent, decodeCelsius, decodeSeconds, decodeMSeconds, decodeFrequency, decodeRGB,
    decodeLimitedDecimal, decodeFlags, decodeColorSet, decodeColor, PERCENT_UNIT, CELSIUS_UNIT, SECONDS_UNIT, MSECONDS_UNIT, HZ_UNIT};
