const YES = 'Yes';
const NO = 'No';
const NOVOLINK_BUTTON = 'Short-press cycles through multi light presets, medium-press turns the light off, long-press puts the light into setup mode';
const PWMS = 'PWMs';
const WHITE_ONLY = 'White Only';
const GPIO_25 = 'RX1/SCL/IO12';
const BRIGHTNESS_CCT_PWMS = 'Brightness/CCT PWMs';
const BUTTON_TOGGLES_ON_OFF = 'Button toggles light on/off';
const SETUP_MODE_ONLY = 'Used for Setup Mode Only';
const MCU_UART_PROTOCOL = 'MCU using the Afero UART protocol';
const GPIO_27 = 'TX1/SDA/IO11';
const I2C = 'I2C';
const BP5768D = 'BP5768D';
const BP1658CJ = 'BP1658CJ';
const HORIZONTAL = 'Horizontal';
const VERTICAL = 'Vertical';
const MODULE_GEN1 = 'GEN 1: LA02301';
const MODULE_GEN2A = 'GEN 2: Sengled SLM22014, Leedarson LA02305';
const MODULE_GEN2B = 'GEN 2: LiteON WCBN3606L, WNC DHSK-AF20';
const MODULE_SMALLFFV1 = 'Small FF Lighting V1: Leedarson LA02304';
const MODULE_SMALLFFV2 = 'Small FF Lighting V2: Sengled SLM22012';
const NIGHTLIGHTGPO = 'Night Light GPIO';
const PHYSICALBUTTONWHENNOGPIO = `Does your light have a physical button? (No GPIO's Available to Select)`;
const PHYSICALBUTTONWHENGPIO = `Does your light have a physical button?`;
const NIGHTLIGHTWHENNOGPIO = `Does your light have a separate night light? (No GPIO's Available to Select)`;
const NIGHTLIGHTWHENGPIO = `Does your light have a separate night light?`;
const LIGHTRGBSUPPORT = `Does your light support RGB and white, or just white LEDs?`;
const MODULEUSED = `Which module is being used?`;
const QUESTION_TYPES = { multi: 'multi', config: 'config', table: 'table' };
let pages500 = (config) => {
    return [
        {
            id: 1,
            name: "Light Controlled with?",
            questions: [
                {
                    type: QUESTION_TYPES.multi,
                    question: "Is your light controlled with PWMs directly from the Wi-Fi module or through an MCU or I2C chip?",
                    answers: [
                        { key: PWMS, nextPage: 11 },
                        { key: MCU_UART_PROTOCOL, nextPage: 8 },
                        { key: I2C, nextPage: 19 },
                    ],
                    answer: ''
                }
            ]
        },
        {
            id: 2,
            name: 'Question has RGB',
            questions: [
                {
                    question: "Does your light support RGB and white, or just white LEDs?",
                    type: QUESTION_TYPES.multi,
                    answers: [
                        { key: 'RGB + White', nextPage: 4 },
                        { key: WHITE_ONLY, nextPage: 5 }
                    ],
                    answer: ''
                },
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 44),
                    question: 'PWM Frequency (in Hz)?'
                },
            ]
        },
        {
            id: 3,
            name: 'Complete',
            questions: [
                {
                    question: "You have completed the configuration setup. By clicking Submit, this configuration will be applied to your light. ",
                    type: QUESTION_TYPES.multi,
                    answers: [],
                }
            ]
        },
        {
            id: 4,
            name: "RGB GPIOs",
            questions: [
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 8),
                    question: config.find(attr => attr.id === 8).title
                },
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 9),
                    question: config.find(attr => attr.id === 9).title
                },
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 10),
                    question: config.find(attr => attr.id === 10).title
                }
            ],
            nextPage: 5
        },
        {
            id: 5,
            name: 'White LEDs controlled by?',
            questions: [
                {
                    question: "Are the white LEDs controlled via Brightness/CCT PWMs, or Warm White and Cool White PWMs?",
                    type: QUESTION_TYPES.multi,
                    answers: [
                        { key: BRIGHTNESS_CCT_PWMS, nextPage: 7 },
                        { key: 'Warm White and Cool White PWMs', nextPage: 12 }
                    ],
                }
            ]
        },
        {
            id: 6,
            name: '3 Channels',
            questions: [
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 2),
                    question: 'Channel 1 GPIO (Warm)'
                },
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 3),
                    question: 'Channel 1 CCT Value (Warm)'
                },
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 4),
                    question: 'Channel 2 GPIO'
                },
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 5),
                    question: 'Channel 2 CCT Value'
                },
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 6),
                    question: 'Channel 3 GPIO (Cool)'
                },
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 7),
                    question: 'Channel 3 CCT Value (Cool)'
                }
            ],
            nextPage: 10
        },
        {
            id: 7,
            name: 'Brightness GPIO White',
            questions: [
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 2),
                    question: 'Brightness GPIO'
                },
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 4),
                    question: 'CCT GPIO'
                },
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 5),
                    question: 'Minimum CCT Value'
                },
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 7),
                    question: 'Maximum CCT Value'
                },
                {
                    question: "Some hardware requires a delay between turning off the brightness PWM and the CCT PWM. This delay prevents the light from briefly changing the CCT when the light is turned off from the mobile app. Would you like to add a delay?",
                    type: QUESTION_TYPES.multi,
                    answers: [
                        { key: YES },
                        { key: NO }
                    ],
                }
            ],
            nextPage: 10
        },
        {
            id: 8,
            name: 'uart protocol',
            questions: [
                {
                    type: QUESTION_TYPES.config,
                    question: 'MCU UART Tx GPIO',
                    config: config.find(attr => attr.id === 27),
                },
                {
                    type: QUESTION_TYPES.config,
                    question: 'MCU UART Rx GPIO',
                    config: config.find(attr => attr.id === 28),
                },
                {
                    type: QUESTION_TYPES.multi,
                    question: 'Does your light have a MCU Reset GPIO?',
                    answers: [
                        { key: YES, nextPage: 9 },
                        { key: NO, nextPage: 23 },
                    ]
                },
                {
                    type: QUESTION_TYPES.multi,
                    question: 'Is the MCU using the v2 version of the Afero UART protocol?',
                    answers: [
                        { key: YES },
                        { key: NO },
                    ]
                },
                {
                    type: QUESTION_TYPES.multi,
                    question: 'Does the MCU expect to receive RGB values to represent white light?',
                    answers: [
                        { key: YES },
                        { key: NO },
                    ]
                },
                {
                    type: QUESTION_TYPES.config,
                    question: 'How many individually addressable lights are on the product?',
                    config: config.find(attr => attr.id === 30),
                },
            ],
        },
        {
            id: 9,
            name: 'uart values',
            questions: [
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 29),
                    question: 'MCU Reset GPIO'
                },
            ],
            nextPage: 23
        },
        {
            id: 10,
            name: 'minimum brightness',
            questions: [
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 11),
                    question: 'What is the minimum brightness that can drive the light?'
                }
            ],
            nextPage: 15
        },
        {
            id: 11,
            name: 'Question Flags',
            questions: [
                {
                    type: QUESTION_TYPES.multi,
                    question: 'Does this light support having both white and RGB LEDs on at the same time?',
                    answers: [
                        { key: YES },
                        { key: NO }
                    ],
                    answer: ''
                },
                {
                    type: QUESTION_TYPES.multi,
                    question: 'Are the PWMs active low?',
                    answers: [
                        { key: YES },
                        { key: NO, nextPage: 22 }
                    ],
                    answer: ''
                }
            ],
            nextPage: 2
        },
        {
            id: 12,
            name: 'Question How many Channels',
            questions: [
                {
                    type: QUESTION_TYPES.multi,
                    question: 'How many channels do you have?',
                    answers: [
                        { key: '1', nextPage: 14 },
                        { key: '2', nextPage: 13 },
                        { key: '3', nextPage: 6 }
                    ],
                    answer: ''
                }
            ]
        },
        {
            id: 13,
            name: 'two channels',
            questions: [
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 4),
                    question: 'Channel 1 GPIO (Warm)'
                },
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 5),
                    question: 'Channel 1 CCT value (Warm)'
                },
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 6),
                    question: 'Channel 2 GPIO (Cool)'
                },
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 7),
                    question: 'Channel 2 CCT value (Cool)'
                }
            ],
            nextPage: 10
        },
        {
            id: 14,
            name: 'One Channel',
            questions: [
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 4),
                    question: 'Channel GPIO'
                },
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 5),
                    question: 'Channel CCT value'
                }
            ],
            nextPage: 10
        },
        {
            id: 15,
            name: 'Question Physical Button',
            questions: [
                {
                    type: QUESTION_TYPES.multi,
                    question: 'Does your light have a physical button?',
                    answers: [
                        { key: YES, nextPage: 16 },
                        { key: NO, nextPage: 17 },
                    ],
                    answer: ''
                }
            ]
        },
        {
            id: 16,
            name: 'Button Configuration',
            questions: [
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 31),
                    question: 'Button GPIO'
                },
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 32),
                    question: 'Button LED GPIO'
                },
                {
                    type: QUESTION_TYPES.multi,
                    question: 'Is the button GPIO active low?',
                    answers: [
                        { key: YES },
                        { key: NO },
                    ],
                    answer: ''
                },
                {
                    type: QUESTION_TYPES.multi,
                    question: 'Is the button LED active low?',
                    answers: [
                        { key: YES },
                        { key: NO },
                    ],
                    answer: ''
                },
                {
                    type: QUESTION_TYPES.multi,
                    question: 'Is the button a toggle?',
                    answers: [
                        { key: YES },
                        { key: NO },
                    ],
                    answer: ''
                },
                {
                    type: QUESTION_TYPES.multi,
                    question: 'How should the button behave?',
                    answers: [
                        { key: NOVOLINK_BUTTON },
                        { key: BUTTON_TOGGLES_ON_OFF },
                        { key: SETUP_MODE_ONLY },
                    ],
                    answer: ''
                }
            ],
            nextPage: 17
        },
        {
            id: 17,
            name: 'Question Night light',
            questions: [
                {
                    type: QUESTION_TYPES.multi,
                    question: 'Does your light have a separate night light?',
                    answers: [
                        { key: YES, nextPage: 18 },
                        { key: NO, nextPage: 3 },
                    ],
                    answer: ''
                }
            ]
        },
        {
            id: 18,
            name: 'Night light configs',
            questions: [
                {
                    type: QUESTION_TYPES.config,
                    question: 'Night Light GPIO',
                    config: config.find(attr => attr.id === 42)
                },
                {
                    type: QUESTION_TYPES.config,
                    question: 'What duty cycle should be set for the PWM output to the night light?',
                    config: config.find(attr => attr.id === 43)
                }
            ],
            nextPage: 3
        },
        {
            id: 19,
            name: 'i2c',
            questions: [
                {
                    type: QUESTION_TYPES.multi,
                    question: 'Which I2C chip is being used?',
                    answers: [
                        { key: BP5768D },
                        { key: BP1658CJ, nextPage: 20 },
                    ],
                },
                {
                    type: QUESTION_TYPES.config,
                    question: 'I2C SDA GPIO?',
                    config: config.find(attr => attr.id === 27)
                },
                {
                    type: QUESTION_TYPES.config,
                    question: 'I2C SCL GPIO?',
                    config: config.find(attr => attr.id === 28)
                },
                {
                    type: QUESTION_TYPES.config,
                    question: 'Max RGB mA output?',
                    config: config.find(attr => attr.id === 38)
                },
                {
                    type: QUESTION_TYPES.config,
                    question: 'Max CCT mA output?',
                    config: config.find(attr => attr.id === 39)
                }
            ],
            nextPage: 23
        },
        {
            id: 20,
            name: 'Rgb output mapping',
            questions: [
                {
                    type: QUESTION_TYPES.table,
                    question: 'What is the RGB output mapping?',
                    config: config.find(attr => attr.id === 41),
                    rows: ['Red', 'Green', 'Blue'],
                    columns: [1, 2, 3]
                }
            ],
            nextPage: 23
        },
        {
            id: 21,
            name: 'high gpio',
            questions: [
                {
                    type: QUESTION_TYPES.config,
                    question: 'GPIO number that we\'ll drive high whenever any of the light is on',
                    config: config.find(attr => attr.id === 17)
                }
            ],
            nextPage: 2
        },
        {
            id: 22,
            name: 'high gpio question',
            questions: [
                {
                    type: QUESTION_TYPES.multi,
                    question: 'Does your hardware require a signal to indicate when the light is off to put it into a low power mode?',
                    answers: [
                        { key: YES, nextPage: 21 },
                        { key: NO, nextPage: 2 },
                    ]
                }
            ],
            nextPage: 2
        },
        {
            id: 23,
            name: 'max and min cct',
            questions: [
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 5),
                    question: 'Minimum CCT value'
                },
                {
                    type: QUESTION_TYPES.config,
                    config: config.find(attr => attr.id === 7),
                    question: 'Maximum CCT value'
                },
            ],
            nextPage: 10
        },
        {
            id: 24,
            name: 'Pin setup',
            questions: [
                {
                    type: QUESTION_TYPES.multi,
                    question: 'Is your radio module horizontally or vertically mounted?',
                    answers: [
                        { key: HORIZONTAL },
                        { key: VERTICAL },
                    ]
                },
                {
                    type: QUESTION_TYPES.multi,
                    question: 'Which module is being used?',
                    answers: [
                        { key: MODULE_GEN1 },
                        { key: MODULE_GEN2A },
                        { key: MODULE_GEN2B },
                        { key: MODULE_SMALLFFV1 },
                        { key: MODULE_SMALLFFV2 },
                    ]
                }
            ],
            nextPage: 1
        }
    ]
}

let pages501 = (config) => {
    return [
        {
            id: 1,
            name: 'light controlled',
            questions: [
                {
                    type: QUESTION_TYPES.multi,
                    question: "How is your light controlled?",
                    answers: [
                        { key: 'Warm White and Cool White PWMs', version: '2', nextPage: 3 },
                        { key: '3 separate white channels', version: '2', nextPage: 3 },
                        { key: 'CCT and Brightness PWMs', nextPage: 2 },
                        { key: 'i2c or UART', version: '2', nextPage: 3 },
                    ],
                    answer: ''
                }
            ]
        },
        {
            id: 2,
            name: 'Adjustable CCT brightness',
            questions: [
                {
                    type: QUESTION_TYPES.multi,
                    question: "Do you need to be able to adjust the brightness for different CCT values?",
                    answers: [
                        { key: YES, version: '3' },
                        { key: NO, version: '1' }
                    ],
                    answer: ''
                }
            ],
            nextPage: 3
        },
        {
            id: 3,
            questions: [
                {
                    type: QUESTION_TYPES.config,
                    config: config[0],
                    question: 'Minimum CCT Value?'
                },
                {
                    type: QUESTION_TYPES.config,
                    config: config[2],
                    question: 'Mid CCT Value?'
                },
                {
                    type: QUESTION_TYPES.config,
                    config: config[1],
                    question: 'Maximum CCT Value?'
                }
            ]
        }
    ]
}

export {
    pages500, pages501, YES, NO, NOVOLINK_BUTTON, PWMS, I2C, GPIO_25, WHITE_ONLY, GPIO_27, BP5768D, BP1658CJ,
    BRIGHTNESS_CCT_PWMS, BUTTON_TOGGLES_ON_OFF, SETUP_MODE_ONLY, MCU_UART_PROTOCOL, HORIZONTAL, VERTICAL,
    MODULE_GEN2B, MODULE_GEN2A, MODULE_GEN1, QUESTION_TYPES, MODULE_SMALLFFV1, MODULE_SMALLFFV2, NIGHTLIGHTGPO,
    PHYSICALBUTTONWHENNOGPIO, PHYSICALBUTTONWHENGPIO, NIGHTLIGHTWHENNOGPIO, NIGHTLIGHTWHENGPIO, LIGHTRGBSUPPORT, MODULEUSED
}
