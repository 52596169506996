import React, {Component} from "react";
import {Button} from "semantic-ui-react";
import ReactTooltip from "react-tooltip";

export default class FilterComponent extends Component{
    constructor(props) {
        super(props);
        this.state = {
            device: props.device,
            handle_devAttrFilter: props.handle_devAttrFilter,
            setFilter: props.setFilter,
            attrFilter: props.attrFilter,
            filterBtnClicked: 'Configurable'
        };
        this.state.deviceDetails = props.userState.devDetails?.find((dev) => dev.deviceId === this.state.device.deviceId)
        this.sparkleFilterLogic = this.sparkleFilterLogic.bind(this);
        if(this.isSparkleLightDevice()){
            props.setFilter(this.sparkleFilterLogic)
        } else {
            props.setFilter(() => true)
        }
    }

    render() {
        return (
            <div>
                {this.isSparkleLightDevice()?
                <Button.Group className={'ui fluid inverted menu'} >
                    <div className={'item'}>Filter:</div>
                    <Button id={'all'} className={this.state.filterBtnClicked === 'all'?'active item': 'item'}
                            data-tip data-for='AllValuesTip'
                            onClick={(e) => this.toggleFilter(e, this.state.handle_devAttrFilter)}>All</Button>
                    <Button id={'Configurable'} className={this.state.filterBtnClicked === 'Configurable'?'active item': 'item'}
                            data-tip data-for='ConfigurableValuesTip'
                            onClick={(e) => this.toggleFilter(e, this.state.handle_devAttrFilter)}>Configurable</Button>
                    <Button id={'NotConfigurable'} className={this.state.filterBtnClicked === 'NotConfigurable'?'active item': 'item'}
                            data-tip data-for='NotConfigurableValuesTip'
                            onClick={(e) => this.toggleFilter(e, this.state.handle_devAttrFilter)}>Non Configurable</Button>
                    <ReactTooltip id='AllValuesTip' type='light' effect='solid' place='top'>
                        <span>All device attributes.</span>
                    </ReactTooltip>
                    <ReactTooltip id='ConfigurableValuesTip' type='light' effect='solid' place='top'>
                        <span>Device attributes that are editable.</span>
                    </ReactTooltip>
                    <ReactTooltip id='NotConfigurableValuesTip' type='light' effect='solid' place='top'>
                        <span>Device attributes that are not editable. You can “test” them by clicking the pencil icon and changing the value. </span>
                    </ReactTooltip>
                </Button.Group>:null}
            </div>
        )
    }

    toggleFilter(event, filter) {
        for (let i = 0; i < event.target.parentElement.children.length; i++) {
            event.target.parentElement.children[i].classList.remove('active');
        }
        event.target.classList.add('active');
        this.setState({filterBtnClicked: event.target.id}, () => {filter(event.target.id)});
    }

    sparkleFilterLogic(attr) {
        if (this.state.filterBtnClicked === 'all') {
            return true;
        }
        if (this.state.filterBtnClicked === 'Configurable') {
            return attr.attributeId === 500 || attr.attributeId === 501 || attr.attributeId === 503 || attr.attributeId === 510;
        } else if (this.state.filterBtnClicked === 'NotConfigurable') {
            return attr.attributeId !== 500 && attr.attributeId !== 501 && attr.attributeId !== 503 && attr.attributeId !== 510;
        }
    }

    isSparkleLightDevice() {
        return isSparkleLightDevice(this.state.deviceDetails.deviceIconUrl, this.state.device.attributes);
    }
}

function isSparkleLightDevice(deviceIconUrl, attributes) {
    if(!deviceIconUrl.includes('device_icon_light')){
        return false;
    }
    return hasAttributId(attributes, 500) && hasAttributId(attributes, 501) && hasAttributId(attributes, 503) && hasAttributId(attributes, 510);
}

function hasAttributId(attributes, id){
    return attributes.findIndex((x=>x.attributeId===id))>-1;
}

export {isSparkleLightDevice}
